export const getLink = (deviceType) => {
  if (deviceType === "fda_denovo" || deviceType === "denovo") {
    return "https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/denovo.cfm?id=";
  } else if (
    deviceType === "fda_pma" ||
    deviceType === "PMA" ||
    deviceType === "pma"
  ) {
    return "https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpma/pma.cfm?id=";
  } else if (deviceType === "fda_510k" || deviceType === "510k") {
    return "https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm?ID=";
  } else {
    return "https://kipra.ai";
  }
};

export const convertDatetime = (dateString) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString); // parse as UTC

  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const buildRegLink = (regNum) => {
  return (
    "https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/cfrsearch.cfm?fr=" +
    regNum
  );
};

export const buildProCodeLink = (proCode) => {
  return (
    "https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpcd/classification.cfm?id=" +
    proCode
  );
};

export const currentYear = new Date().getFullYear();
