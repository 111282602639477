import React, { useRef, useEffect, useState } from "react";
import { GuidanceBodyProfile } from "./ProfileBody.jsx";

function GuidanceProfile(props) {
  const profileRef = useRef(null);
  const [resource, setResource] = useState(null);
  const [profileOn, setProfileOn] = useState(true);

  useEffect(() => {
    const fetchResourceDetail = async () => {
      try {
        // clear
        setResource(null);
        const response = await fetch(
          "https://kipra.ai/flask_app/detail?type=guidance&id=" + props.id,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setResource(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (props.id) {
      fetchResourceDetail();
    }
  }, [props.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        props.onStateChange();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <>
      {props.status === "show" && (
        <div className="offcanvas-backdrop fade show" />
      )}
      <div
        className={
          "offcanvas offcanvas-end " + (props.status === "show" ? "show" : "")
        }
        data-bs-scroll="false"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{
          visibility: props.status === "show" ? "visible" : "hidden",
          padding: "0 11px",
        }}
        {...(props.status === "show"
          ? { "aria-modal": true, "aria-hidden": "false" }
          : { "aria-hidden": "true" })}
        role="dialog"
        ref={profileRef}
      >
        {resource ? (
          <>
            <div className="offcanvas-header">
              <h4 className="offcanvas-title">
                <div>
                  {"Guidance Details "}
                  <a
                    href={resource.guidance_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "10pt" }}>
                      <i className="uil uil-external-link-alt ms-2"></i> FDA
                    </span>
                  </a>
                </div>
              </h4>
              <button
                className="btn-close me-1"
                onClick={props.onStateChange}
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="mb-2" style={{ fontSize: "14pt" }}>
                {resource.guidance_title}
              </div>
              <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
                <li className="nav-item">
                  <a
                    className={"nav-link" + (profileOn ? " active" : "")}
                    onClick={() => setProfileOn(true)}
                  >
                    <i className="mdi mdi-bank d-md-none d-block" />
                    <span className="d-none d-md-block">FDA Guidance</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link" + (profileOn ? "" : " active")}
                    onClick={() => setProfileOn(false)}
                  >
                    <i className="mdi mdi-file-document-outline d-md-none d-block" />
                    <span className="d-none d-md-block">Additional Info</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <GuidanceBodyProfile
                  profileOn={profileOn}
                  resource={resource}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            className="loading-container"
            style={{ height: "100%", width: "100%" }}
          >
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default GuidanceProfile;
