import React, { useRef, useEffect, useState } from "react";
import { getLink } from "../utils/utils.js";
import { DenovoProfile, _510kProfile, PmaProfile } from "./ProfileBody.jsx";

const DeviceProfile = (deviceType, profileOn, device) => {
  if (deviceType === "fda_denovo" || deviceType === "denovo") {
    return <DenovoProfile profileOn={profileOn} device={device} />;
  } else if (deviceType === "fda_510k" || deviceType === "510k") {
    return <_510kProfile profileOn={profileOn} device={device} />;
  } else if (deviceType === "fda_pma" || deviceType === "pma") {
    return <PmaProfile profileOn={profileOn} device={device} />;
  }
};

function Profile(props) {
  const profileRef = useRef(null);

  const [baseLinkFDA, setBaseLinkFDA] = useState(null);
  const [device, setDevice] = useState(null);
  const [profileOn, setProfileOn] = useState(true);

  useEffect(() => {
    const fetchDeviceDetail = async () => {
      try {
        // clear device
        setDevice(null);
        const response = await fetch(
          "https://kipra.ai/flask_app/detail?type=" +
            props.deviceType +
            "&id=" +
            props.deviceNum.replace(/\//g, ""),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setBaseLinkFDA(getLink(props.deviceType));
        setDevice(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (props.deviceNum != null) {
      fetchDeviceDetail();
    }
  }, [props.deviceNum]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        props.onStateChange();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <>
      {props.status === "show" && (
        <div className="offcanvas-backdrop fade show" />
      )}
      <div
        className={
          "offcanvas offcanvas-end " + (props.status === "show" ? "show" : "")
        }
        data-bs-scroll="false"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{
          visibility: props.status === "show" ? "visible" : "hidden",
          padding: "0 11px",
        }}
        {...(props.status === "show"
          ? { "aria-modal": true, "aria-hidden": "false" }
          : { "aria-hidden": "true" })}
        role="dialog"
        ref={profileRef}
      >
        {device ? (
          <>
            <div className="offcanvas-header">
              <h4 className="offcanvas-title">
                <div>
                  {props.deviceNum + " "}
                  <a
                    href={baseLinkFDA + props.deviceNum.replace(/\//g, "")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "10pt" }}>
                      <i className="uil uil-external-link-alt ms-2"></i> FDA
                    </span>
                  </a>
                  <span style={{ fontSize: "10pt" }}> |</span>
                  <a
                    href="https://kipra.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "10pt" }}>
                      <i className="uil uil-external-link-alt ms-1"></i> KIPRA
                    </span>
                  </a>
                </div>
              </h4>
              <button
                className="btn-close me-1"
                onClick={props.onStateChange}
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="mb-2" style={{ fontSize: "14pt" }}>
                {device.device_name || device.device}
              </div>
              <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
                <li className="nav-item">
                  <a
                    className={"nav-link" + (profileOn ? " active" : "")}
                    onClick={() => setProfileOn(true)}
                  >
                    <i className="mdi mdi-bank d-md-none d-block" />
                    <span className="d-none d-md-block">Profile</span>
                  </a>
                </li>
                {(props.deviceType === "510k" ||
                  props.deviceType === "denovo") && (
                  <li className="nav-item">
                    <a
                      className={"nav-link" + (profileOn ? "" : " active")}
                      onClick={() => setProfileOn(false)}
                    >
                      <i className="mdi mdi-file-document-outline d-md-none d-block" />
                      <span className="d-none d-md-block">IU & IFU</span>
                    </a>
                  </li>
                )}
                {props.deviceType === "pma" && (
                  <li className="nav-item">
                    <a
                      className={"nav-link" + (profileOn ? "" : " active")}
                      onClick={() => setProfileOn(false)}
                    >
                      <i className="mdi mdi-file-document-outline d-md-none d-block" />
                      <span className="d-none d-md-block">AO Statement</span>
                    </a>
                  </li>
                )}
              </ul>
              <div className="tab-content">
                {DeviceProfile(props.deviceType, profileOn, device)}
              </div>
            </div>
          </>
        ) : (
          <div
            className="loading-container"
            style={{ height: "100%", width: "100%" }}
          >
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;
